#demos {
    width: 100%;
    height: 100%;
}

#demos .demos-outer {
    display: flex;
    flex-direction: column;
}

.demos-left {
    width: 100%;
    padding: 40px 20px;
}


.demos-left-header {
    font-weight: 600;
    font-size: 22px;
    margin-top: 20px;
    line-height: 1.4;
    letter-spacing: 1px;
    margin-bottom: 15px;
}

.demos-right {
    background-color: rgb(242, 243, 247);
    height: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
}

.demos-right-question {
    background-color: #202b4a;
    padding: 40px 40px 35px;
    border-radius: 28px;
    color: #ccc;
    line-height: 1.8;
    text-align: center;
    position: relative;
}

.demos-right-image {
    width: 100%;
    box-sizing: border-box;
    text-align: center;

}

.demos-right-image img {
    height: 80px;
    width: 80px;
    margin-top: 10px;
    object-fit: contain;
    filter: grayscale(100%);

}

.demos-left-desc {
    margin-bottom: 20px;
}

@media(min-width:990px) {
    #demos {
        width: 100%;
        height: 100vh;
    }

    #demos .demos-outer {
        display: flex;
        flex-direction: row;
    }

    .demos-left {
        flex: 1;
        height: 100vh;
        padding: 80px 100px;
        box-sizing: border-box;
    }

    .demos-right {
        background-color: rgb(242, 243, 247);
        height: 100vh;
        padding: 80px 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        box-sizing: border-box;
    }

    .demos-left-header {
        font-weight: 800;
        font-size: 22px;
        margin-top: 20px;
        line-height: 1.4;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }

    .demos-left-desc {
        margin-bottom: 20px;
        line-height: 1.4;
    }

    .demos-left-form {
        margin-top: 40px;
    }

    .demos-right-question {
        background-color: #202b4a;
        padding: 40px 40px 35px;
        border-radius: 28px;
        color: #ccc;
        line-height: 1.8;
        text-align: center;
        position: relative;
    }

    .demos-right-pointer {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 30px;
        height: 30px;
        transform: rotate(45deg) translate(0, 50%);
        background-color: #202b4a;
        margin-left: -4px;
    }

    .demos-right-image {
        width: 33%;
        box-sizing: border-box;
        text-align: center;

    }

    .demos-right-image img {
        object-fit: contain;
        height: 80px;
        width: 80px;
        filter: grayscale(100%);
    }


}
