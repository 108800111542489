.inputform {
    margin-bottom: 22px;
    width: 100%;

}

.input-layout {
    border: 1px solid #eee;
    border-radius: 4px;
}

.input-layout  input {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    padding: 5px 12px;
}

.inputform .input-title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
}

@media (min-width:768px) {
    .inputform {
        margin-bottom: 22px;
    
    }
    
    .input-layout {
        width: 100%;
        border: 1px solid #eee;
        border-radius: 4px;
    }
    
    .input-layout  input {
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 4px;
        font-size: 18px;
        padding: 5px 12px;
    }
    
    .inputform .input-title {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 5px;
    }
}